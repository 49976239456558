import { createRouter, createWebHistory } from "vue-router";

function checkValidity() {
  const token = localStorage.getItem("breton-token");
  const userInfo = localStorage.getItem("breton-userInfo");
  if (token && userInfo) return true;
  return false;
}

function isAdmin() {
  const token = localStorage.getItem("breton-token");
  const userInfo = localStorage.getItem("breton-userInfo");
  if (token && userInfo && JSON.parse(userInfo).is_admin) return true;
  return false;
}

const routes = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "MainLayout" */ "../layout/PublicLayout.vue"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "LoginView" */ "../views/LoginView.vue"),
      },
      {
        path: "/session/reset-password/:token/:uidb",
        name: "Session Password Reset",
        component: () =>
          import(/* webpackChunkName: "NewPasswordView" */ "../views/NewPasswordView.vue"),
      },
      {
        path: "/reset-password",
        name: "Forgot Password",
        component: () =>
          import(/* webpackChunkName: "NewPasswordView" */ "../views/ResetPasswordView.vue"),
      },
    ],
  },
  {
    path: "/home",
    component: () => import(/* webpackChunkNames: "MainLayout" */ "../layout/MainLayout.vue"),
    meta: { requiresAuth: true },
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "DashboardView" */ "../views/DashboardView.vue"),
      },
      {
        path: "/person",
        name: "Person",
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import(/* webpackChunkName: "PersonsView" */ "../views/PersonView.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "ProfileView" */ "../views/ProfileView.vue"),
      },
      {
        path: "/investment",
        name: "Investment",
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "InvestmentView" */ "../views/InvestmentView.vue"),
      },
      {
        path: "/investor",
        name: "Investor",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "InvestorView" */ "../views/InvestorView.vue"),
      },
      {
        path: "/role",
        name: "Role",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "RoleView" */ "../views/RoleView.vue"),
      },
      {
        path: "/logout",
        name: "Logout",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "LogoutView" */ "../views/LogoutView.vue"),
      },
      {
        path: "/mfa",
        name: "Multi-factor Authentication",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "MFASetupView" */ "../views/MFASetupView.vue"),
      },
      {
        path: "/downloads",
        name: "File Downloads",
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () =>
          import(/* webpackChunkName: "DownloadsView" */ "../views/DownloadsView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isValid = checkValidity();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const userIsAdmin = isAdmin();

  if (requiresAuth && !isValid) {
    next("/login");
  } else if (requiresAuth && isValid && requiresAdmin && !userIsAdmin) {
    next("/dashboard");
  } else {
    next();
  }
});

export default router;
