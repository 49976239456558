import axios from "@/plugins/axios";

const state = {
  roleList: [],
};

const getters = {
  roleList: (state) => {
    return state.roleList;
  },
};

const actions = {
  async getRoles(context) {
    // NProgress.start();
    return axios.get(`/role`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      } else {
        context.commit("SET_ROLE_LIST", resData.roles);
      }
    });
  },
  async addRole(context, payload) {
    try {
      const { data } = await axios.post(`/role`, {
        role_type: payload.role_type,
        person_id: payload.person_id,
        investor_id: payload.investor_id,
        entity_id: payload.entity_id,
        version: payload.version,
      });
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("ADD_ROLE", data.role);
      return true;
    } catch (e) {
      //error
    }
  },
  async deleteRole(context, payload) {
    try {
      const { data } = await axios.delete("/role", {
        data: {
          entity_id: payload.entity_id,
        },
      });
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("DELETE_ROLE", payload);
      return true;
    } catch (e) {
      //error
    }
  },
};

const mutations = {
  SET_ROLE_LIST(state, data) {
    state.roleList = data;
    // NProgress.done()
  },
  ADD_ROLE(state, data) {
    for (var i = 0; i < state.roleList.length; i++) {
      if (state.roleList[i].entity_id == data.entity_id) {
        state.roleList[i] = data;
        return;
      }
    }
    state.roleList.push(data);
    // NProgress.done()
  },
  DELETE_ROLE(state, payload) {
    let index = state.roleList.findIndex((e) => e.entity_id == payload.entity_id);
    if (index > -1) {
      state.roleList.splice(index, 1);
    }
    // NProgress.done()
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
