import axios from "axios";
import localService from "../services/localService";
import { store } from "../main";

const GET_API_URL = () => {
  return process.env.VUE_APP_API_URL;
};

const startLoading = () => {
  store.commit("login/startLoading", null, { root: true });
};

const stopLoading = () => {
  store.commit("login/stopLoading", null, { root: true });
};

const options = {
  baseURL: GET_API_URL(),
  headers: {
    "Content-Type": "application/json",
  },
};

const instance = axios.create({ ...options });

instance.interceptors.request.use((config) => {
  startLoading();
  const token = localService.getItem("breton-token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    stopLoading();
    return response;
  },
  (error) => {
    stopLoading();

    if (error?.response?.status === 401) {
      store.dispatch("login/logout");
    }

    return Promise.reject(error);
  },
);

export default instance;
