import axios from "../plugins/axios";
import localService from "../services/localService";
import router from "../router";
import { getField, updateField } from "vuex-map-fields";

///handlePostLogin
function handlePostLogin(commit, data) {
  const loginType = {
    type: "normal",
    provider: "signup",
  };

  commit("setState", { key: "currentUser", value: data["person"] });
  commit("setState", { key: "isLoggedIn", value: true });
  localService.setItem("breton-userInfo", {
    ...data["person"],
  });
  localService.setItem("breton-token", data["access_token"]);
  localService.setItem("breton-loginType", loginType);
  router.push({ path: "/dashboard" });
}

export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    currentUser: {},
    isLoggedIn: false,
    loginType: {},
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    resetState(state) {
      state.currentUser = {};
      state.isLoggedIn = false;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },
  },
  actions: {
    //
    // USED FOR LOGGING IN A USER
    //
    async login({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/login", payload);
        if (!data.success) {
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        if (data.person.mfa_enabled) {
          return { showMfa: true };
        }

        handlePostLogin(commit, data);
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
    //
    // USED FOR LOGGING-OUT A USER
    //
    async logout({ commit }) {
      try {
        commit("resetState");
        localService.removeItem("breton-token");
        localService.removeItem("breton-userInfo");
        router.push({ path: "/" });
      } catch (e) {
        //error
      } finally {
      }
    },
    //
    // USED TO RESET PASSWORD FOR A ACCOUNT
    //
    async forgotPassword({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/reset_password", payload);
        if (!data.success) {
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        dispatch(
          "showMessage",
          { ...data, messageType: "success", messageTitle: "Success" },
          { root: true },
        );
        return data;
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
    //
    // USED TO CONFIRM EMAIL
    //
    async confirmEmail({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post(`/verify/${payload.token}/${payload.uidb}`);
        if (!data.success) {
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        setTimeout(() => {
          commit("setState", { key: "currentUser", value: data["user"] });
          commit("setState", { key: "isLoggedIn", value: true });
          localService.setItem("userInfo", {
            ...data["user"],
          });
          localService.setItem("token", data["user"]["access_token"]);
          app.$router.push({ path: "/dashboard" });
        }, 3000);
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
    //
    // RESEND CONFIRMATION LINK
    //
    async resendEmail({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post(`/resend_confirmation`, payload);
        if (!data.success) {
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        dispatch(
          "showMessage",
          { ...data, messageType: "success", messageTitle: "Success" },
          { root: true },
        );
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
    //
    // RESET THE PASSWORD
    //
    async resetPassword({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post(`/reset_password/${payload.token}/${payload.uidb}`, {
          password: payload.password,
        });
        if (!data.success) {
          if (data.message === "Reset Password token has expired") {
            setTimeout(function () {
              window.location = "/reset-password";
            }, 3000);
          }
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        dispatch(
          "showMessage",
          { ...data, messageType: "success", messageTitle: "Success" },
          { root: true },
        );
        return data;
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
    //
    // MFA LOGIN
    //
    async loginMfa({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/login_mfa", payload);

        if (!data.success) {
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        handlePostLogin(commit, data);
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
    async loginUsingRecovery({ commit, dispatch }, payload) {
      try {
        commit("setState", { key: "isLoading", value: true });
        const { data } = await axios.post("/verify_recovery_code", payload);

        if (!data.success) {
          return dispatch(
            "showMessage",
            { ...data, messageType: "error", messageTitle: "Error" },
            { root: true },
          );
        }
        handlePostLogin(commit, data);
      } catch (e) {
        //error
      } finally {
        commit("setState", { key: "isLoading", value: false });
      }
    },
  },
  getters: {
    getField,
    currentUser: (state) => state.currentUser,
    isLoading: (state) => state.isLoading,
    isLoggedIn: (state) => state.isLoggedIn,
  },
};
