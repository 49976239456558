import axios from "@/plugins/axios";
import { getField, updateField } from "vuex-map-fields";

const state = {
  investmentList: [],
  loadingInvestments: false,
};

const getters = {
  getField,

  investmentList: (state) => {
    return state.investmentList;
  },
};

const actions = {
  async getInvestments(context) {
    // NProgress.start();
    return axios.get(`/investment`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.commit("SET_INVESTMENT_LIST", resData.investments);
      return resData;
    });
  },
  async getInvestmentByEntityID(context, entity_id) {
    // NProgress.start();
    return axios.get(`/investment/${entity_id}`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      } else {
        return resData.investment;
      }
    });
  },
  async addInvestment(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.post(`/investment`, payload);
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("ADD_INVESTMENT", data.investment);
      return true;
    } catch (e) {
      //error
    }
  },
  async deleteInvestment(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.delete(`/investment`, {
        data: { entity_id: payload.entity_id },
      });
      if (!data.success) {
        return context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("DELETE_INVESTMENT", payload);
      return true;
    } catch (e) {
      //error
    }
  },
  async getInvestmentData(context, payload) {
    let response = await axios.get(`/investment/${payload.investment_id}`);
    const resData = response.data;
    if (!resData.success) {
      context.dispatch(
        "showMessage",
        { ...resData, messageType: "error", messageTitle: "Error" },
        { root: true },
      );
      return;
    } else {
      return resData.investment;
    }
  },
};

const mutations = {
  updateField,

  SET_INVESTMENT_LIST(state, data) {
    state.investmentList = data;
    // NProgress.done()
  },

  ADD_INVESTMENT(state, data) {
    for (var i = 0; i < state.investmentList.length; i++) {
      if (state.investmentList[i].entity_id == data.entity_id) {
        state.investmentList[i] = data;
        return;
      }
    }
    state.investmentList.push(data);
    // NProgress.done()
  },

  DELETE_INVESTMENT(state, payload) {
    let index = state.investmentList.findIndex((e) => e.entity_id == payload.entity_id);
    if (index > -1) {
      state.investmentList.splice(index, 1);
    }
    // NProgress.done()
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
