import axios from "@/plugins/axios";
import { v4 as uuidv4 } from "uuid";

const state = {
  personsList: [],
};

const getters = {
  personsList: (state) => {
    return state.personsList;
  },
};

const actions = {
  async getPersons(context) {
    // NProgress.start();
    return axios.get(`/person`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }

      context.commit("SET_PERSONS_LIST", resData.data);
      return resData;
    });
  },
  async getPersonsByInvestorId(context, investor_id) {
    // NProgress.start()
    return axios.get(`/person/investor/${investor_id}`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      return resData;
    });
  },
  async getPersonsInvestorRelated(context) {
    // NProgress.start()
    return axios.get(`/person/related`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      return resData;
    });
  },
  async addPerson(context, payload) {
    // NProgress.start()
    let person = Object.assign({}, payload);
    if (!person.entity_id) {
      person.tempId = "PERSON_" + uuidv4();
      context.commit("ADD_PERSON", person);
    }
    try {
      const { data } = await axios.post(`/person`, {
        entity_id: payload.entity_id,
        version: payload.version,
        first_name: payload.first_name,
        last_name: payload.last_name,
        is_admin: payload.is_admin,
        emails: payload.emails,
        emails_to_delete: payload.emails_to_delete,
      });
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        if (person.tempId) {
          context.commit("DELETE_ADDED_PERSON", { tempId: person.tempId });
        }
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );

      context.commit("UPDATE_ADDED_PERSON", { person: data.person, tempId: person.tempId });
      return data.person;
    } catch (e) {
      //error
    }
  },
  async invitePerson(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.post(`/person/invite`, payload);
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      return true;
    } catch (e) {
      //error
    }
  },
  async resendInvite(context, person_id) {
    // NProgress.start()
    try {
      const { data } = await axios.post(`/person/resend-invitation`, { person_id: person_id });
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      return true;
    } catch (e) {
      //error
    }
  },
  async removePerson(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.post(`/person/remove`, payload);
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      return true;
    } catch (e) {
      //error
    }
  },
  async getEmails(context, payload) {
    let response = await axios.get(`/email/${payload.person_id}`);
    const resData = response.data;
    if (!resData.success) {
      context.dispatch(
        "showMessage",
        { ...resData, messageType: "error", messageTitle: "Error" },
        { root: true },
      );
      return;
    } else {
      return resData.data;
    }
  },
  async deleteUser(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.delete(`/person`, {
        data: { entity_id: payload.entity_id },
      });
      if (!data.success) {
        return context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("DELETE_USER", payload);
      return true;
    } catch (e) {
      //error
    }
  },
};

const mutations = {
  SET_PERSONS_LIST(state, data) {
    state.personsList = data;
    // NProgress.done()
  },
  ADD_PERSON(state, data) {
    state.personsList.push(data);
    // NProgress.done()
  },
  UPDATE_ADDED_PERSON(state, data) {
    let index;
    if (data.tempId) {
      index = state.personsList.findIndex((e) => e.tempId == data.tempId);
    } else {
      index = state.personsList.findIndex((e) => e.entity_id == data.person.entity_id);
    }
    state.personsList.splice(index, 1, data.person);

    // NProgress.done();
  },
  DELETE_ADDED_PERSON(state, data) {
    let index = state.personsList.findIndex((e) => e.tempId == data.tempId);
    state.personsList.splice(index, 1);
    // NProgress.done();
  },
  DELETE_USER(state, payload) {
    let index = state.personsList.findIndex((e) => e.entity_id == payload.entity_id);
    if (index > -1) {
      state.personsList.splice(index, 1);
    }
    // NProgress.done()
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
