import { createApp } from "vue";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

import Notifications from "@kyvg/vue3-notification";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// Vuetify

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
  },
  components,
  directives,
});

import axios from "axios";
import VueAxios from "vue-axios";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(VueAxios, axios);
app.use(Notifications);

app.mount("#app");

export { app, store };
