import { createStore } from "vuex";
import login from "./login";
import person from "./person";
import investment from "./investment";
import investor from "./investor";
import role from "./role";
import file from "./file";
import mfa from "./mfa";

import { notify } from "@kyvg/vue3-notification";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    showMessage({ commit }, payload) {
      return notify({
        type: payload.messageType,
        title: payload.messageTitle,
        text: payload.message,
      });
    },
  },
  modules: {
    login,
    person,
    investment,
    investor,
    role,
    file,
    mfa,
  },
});
