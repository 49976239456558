<template>
  <v-app>
    <router-view />
    <notifications />
  </v-app>
</template>
<script>
import localService from "./services/localService";

export default {
  methods: {
    checkIfUserIsLoggedIn() {
      const userInfo = localService.getItem("breton-userInfo");
      const token = localService.getItem("breton-token");

      if (userInfo && token) {
        this.$store.commit("login/setState", {
          key: "currentUser",
          value: userInfo,
        });
        return this.$store.commit("login/setState", {
          key: "isLoggedIn",
          value: true,
        });
      }
      return this.$store.commit("login/setState", {
        key: "isLoggedIn",
        value: false,
      });
    },
  },

  mounted() {
    this.checkIfUserIsLoggedIn();
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;1,6..12,200&display=swap");
@import "@/assets/scss/global.scss";
</style>
