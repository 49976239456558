import axios from "@/plugins/axios";
import { getField, updateField } from "vuex-map-fields";

const state = {
  fileList: [],
  fileDownloadList: [],
};

const getters = {
  getField,
  fileList: (state) => {
    return state.fileList;
  },
  fileDownloadList: (state) => {
    return state.fileDownloadList;
  },
};

const actions = {
  async getFiles(context) {
    // NProgress.start();
    return axios.get(`/file`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.commit("SET_FILE_LIST", resData.files);
      return resData;
    });
  },
  async getFileDownloads(context) {
    // NProgress.start();
    return axios.get(`/file_download/me`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.commit("SET_FILE_DOWNLOAD_LIST", resData.file_downloads);
      return resData;
    });
  },
  async getFilesForInvestor(context, investor_id) {
    return axios.get(`/file/investor/${investor_id}`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      return resData.files;
    });
  },
  async getFilesForInvestment(context, investment_id) {
    return axios.get(`/file/investment/${investment_id}`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      return resData.files;
    });
  },
  async sendFileToBackend(context, payload) {
    try {
      const { data } = await axios.post(`/file`, payload);

      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }

      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );

      context.commit("ADD_FILE", data.file);

      return true;
    } catch (e) {
      //error
    }
  },
  async deleteFile(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.delete(`/file`, {
        data: { entity_id: payload.entity_id },
      });
      if (!data.success) {
        return context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("DELETE_FILE", payload);
      return true;
    } catch (e) {
      //error
    }
  },
  async sendFileDownloadEvent(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.post(`/file_download`, {
        file_id: payload.entity_id,
        file_url: payload.file_url,
        file_name: payload.file_name,
      });
      if (!data.success) {
        return context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("ADD_FILE_DOWNLOAD", data.file_download);
      return true;
    } catch (e) {
      //error
    }
  },
  async getFileDownloadEvents(context) {
    // NProgress.start();
    return axios.get(`/file_download_events`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      return resData;
    });
  },
};

const mutations = {
  updateField,

  SET_FILE_LIST(state, data) {
    state.fileList = data;
    // NProgress.done()
  },
  SET_FILE_DOWNLOAD_LIST(state, data) {
    state.fileDownloadList = data;
    // NProgress.done()
  },
  ADD_FILE(state, data) {
    for (var i = 0; i < state.fileList.length; i++) {
      if (state.fileList[i].entity_id == data.entity_id) {
        state.fileList[i] = data;
        return;
      }
    }
    state.fileList.push(data);
    // NProgress.done()
  },
  ADD_FILE_DOWNLOAD(state, data) {
    for (var i = 0; i < state.fileDownloadList.length; i++) {
      if (state.fileDownloadList[i].entity_id == data.entity_id) {
        state.fileDownloadList[i] = data;
        return;
      }
    }
    state.fileDownloadList.push(data);
    // NProgress.done()
  },
  DELETE_FILE(state, payload) {
    let index = state.fileList.findIndex((e) => e.entity_id == payload.entity_id);
    if (index > -1) {
      state.fileList.splice(index, 1);
    }
    // NProgress.done()
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
