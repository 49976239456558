import axios from "@/plugins/axios";
import { getField, updateField } from "vuex-map-fields";

const state = {
  investorList: [],
  loadingInvestors: false,
};

const getters = {
  getField,
  investorList: (state) => {
    return state.investorList;
  },
};

const actions = {
  async getInvestors(context) {
    // NProgress.start();
    return axios.get(`/investor`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.commit("SET_INVESTOR_LIST", resData.investors);
      return resData;
    });
  },
  async getInvestorByEntityID(context, entity_id) {
    // NProgress.start();
    return axios.get(`/investor/${entity_id}`).then(function (response) {
      const resData = response.data;
      if (!resData.success) {
        return context.dispatch(
          "showMessage",
          { ...resData, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      } else {
        return resData.investor;
      }
    });
  },
  async addInvestor(context, payload) {
    try {
      const { data } = await axios.post(`/investor`, payload);
      if (!data.success) {
        context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
        return data;
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("ADD_INVESTOR", data.investor);
      return true;
    } catch (e) {
      //error
    }
  },
  async deleteInvestor(context, payload) {
    // NProgress.start()
    try {
      const { data } = await axios.delete(`/investor`, {
        data: { entity_id: payload.entity_id },
      });
      if (!data.success) {
        return context.dispatch(
          "showMessage",
          { ...data, messageType: "error", messageTitle: "Error" },
          { root: true },
        );
      }
      context.dispatch(
        "showMessage",
        { ...data, messageType: "success", messageTitle: "Success" },
        { root: true },
      );
      context.commit("DELETE_INVESTOR", payload);
      return true;
    } catch (e) {
      //error
    }
  },
  async getInvestorData(context, payload) {
    let response = await axios.get(`/investor/${payload.investor_id}`);
    const resData = response.data;
    if (!resData.success) {
      context.dispatch(
        "showMessage",
        { ...resData, messageType: "error", messageTitle: "Error" },
        { root: true },
      );
      return;
    } else {
      return resData.investor;
    }
  },
};

const mutations = {
  updateField,

  SET_INVESTOR_LIST(state, data) {
    state.investorList = data;
    // NProgress.done()
  },
  ADD_INVESTOR(state, data) {
    for (var i = 0; i < state.investorList.length; i++) {
      if (state.investorList[i].entity_id == data.entity_id) {
        state.investorList[i] = data;
        return;
      }
    }
    state.investorList.push(data);
    // NProgress.done()
  },
  DELETE_INVESTOR(state, payload) {
    let index = state.investorList.findIndex((e) => e.entity_id == payload.entity_id);
    if (index > -1) {
      state.investorList.splice(index, 1);
    }
    // NProgress.done()
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
